<template>
    <div>
        <div class="row">
            <div class="col-md-12">

                <KTCard>
                    <template v-slot:title>
                        {{ operationTitle }}
                        <p>
                            <small>Add Brochures to be sent to Guest</small>
                        </p>
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/brochures/create"
                                         v-if="$global.hasPermission('brochuresstore')">
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-b-tooltip.hover :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                        </b-input-group-append>
                    </template>

                    <template v-slot:body>
                        <v-card>
                            <div class="brochures-table"
                                 v-if="$global.hasPermission('brochuresview')">

                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_length"><label>Show
                                                <b-form-select :options="[10, 50, 100]"
                                                               class="datatable-select"
                                                               size="sm"
                                                               v-model="pagination.totalPerPage"></b-form-select>
                                                entries</label></div>

                                        </div>
                                        <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                                        <!--                                            <span v-for="(value,key) in filters"-->
                                        <!--                                                  :key="key">-->
                                        <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                                        <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                                        <!--                                                        <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                           @click="clearFilter(key, [])"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                                <span v-else>-->
                                        <!--                                                    <b-tag class="py-2"-->
                                        <!--                                                           v-if="value && key !== 'visible'"> <span-->
                                        <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                                        <!--                                                    <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                       @click="clearFilter(key , null)"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                            </span>-->
                                        <!--                                        </div>-->
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                                                <b-form-input class="form-control form-control-sm ml-2 w-25"
                                                              label="Search"
                                                              type="search"
                                                              v-model="search"
                                                              v-on:keyup.enter="handleSearch"></b-form-input>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <v-data-table
                                    :headers="columns"
                                    :items="dataSource"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    :loading="loading"
                                    class="table table-head-custom table-vertical-center table-responsive"
                                    hide-default-footer
                                    ref="table"
                                    responsive
                                >
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.file="{item}">
                                        <span v-for="(doc ,index) in item.media" :key="index" class="pr-4">
                                            <span v-if="doc.type === 'link' && doc.link">
                                                {{ doc.link }}
                                            </span>
                                            <span v-else>
                                                <a :href="doc.download_url"
                                                   target="_blank">
                                                      <i class="fa fa-download"></i>
                                                  </a>
                                                  {{ doc.name }}
                                            </span>
                                          </span>
                                    </template>
                                    <template v-slot:item.size="{item}">
                                          <span v-for="(doc ,index) in item.media" :key="index" class="pr-4">
                                              {{ bytesToSize(doc.size) }}
                                          </span>
                                    </template>
                                    <template class="action-column" v-slot:item.action="{item}">
                                        <router-link :to="`/brochures/edit/${item.id}`">
                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                               v-show="$global.hasPermission('brochuresupdate')">
                          <span
                              class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                              <inline-svg
                                  src="/media/svg/icons/Communication/Write.svg"
                                  class="action-edit-button"
                              ></inline-svg>
                              <!--end::Svg Icon-->
                          </span>
                                            </a>
                                        </router-link>
                                        <a @click="handleDeleteOperation(item.id)"
                                           class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           size="sm"
                                           v-show="$global.hasPermission('brochuresdestroy')">
                        <span
                            class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                            <inline-svg
                                class="action-delete-button" src="/media/svg/icons/General/Trash.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                        </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            :per-page="pagination.totalPerPage"
                                            :total-rows="pagination.total"
                                            size="lg"
                                            v-model="pagination.current"
                                        ></b-pagination>

                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>

                <template>
                    <div>
                        <b-sidebar
                            :backdrop-variant="`transparent`"
                            backdrop
                            id="sidebar-backdrop-brochures"
                            shadow
                            title="Filters"
                            right
                        >
                            <template #header="{ hide }">
                                <b-col sm="10">
                                    <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                                </b-col>
                                <b-col sm="2">
                                    <button class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                                        <i class="ki ki-close icon-xs text-muted"></i>
                                    </button>
                                </b-col>
                            </template>
                            <div class="px-3 py-2">
                                <b-col sm="12">
                                    <b-form-group
                                        label="Name Of Brochure"
                                        label-for="title">
                                        <b-form-input class="mb-2" id="filter-title"
                                                      v-model="filters.title"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </div>
                        </b-sidebar>
                    </div>
                </template>

            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import ModuleMixing from "./module.mixin";


const DEFAULT_FILTER_STATE = {
    title: null,
};

export default {
    mixins: [ListingMixin, ModuleMixing],
    data() {
        return {
            filters: { ...DEFAULT_FILTER_STATE },
        };
    },
    components: {
        KTCard
    },
    methods: {
        handleResetFilterClick() {
            this.filters = { ...DEFAULT_FILTER_STATE };
            this.isFilterApplied = 'reset';
            this.checkFilterCountStatus();
        },
    },
    computed: {
        ...mapState([
            'global',
        ]),
    },
};
</script>
