import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { getEventId } from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
  title: null,
  _method: 'post',
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name Of The Brochure",
    sortable: false,
    value: 'title',
  },
  {
    text: "FileName",
    sortable: false,
    value: 'file',
  },
  // {
  //     text: "Size",
  //     sortable: false,
  //     value: 'size',
  // },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  data() {
    return {
      operationTitle: 'Brochures',
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      listUrl: '/brochures',
      columns: COLUMN_DEFINATION(this),
      fPreview: null,
      fFile: null,
    };
  },
  methods: {
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        let formData = new FormData();
        //brochure documents
        for (let i = 0; i < this.brochure_documents.length; i++) {
          if ( this.brochure_documents[i].file !== undefined ) {
            formData.append(`file[${ i }]`, this.brochure_documents[i].file);
            formData.append(`name[${ i }]`, this.brochure_documents[i].name);
          }
          if ( this.brochure_documents[i].video_link !== undefined && this.brochure_documents[i].video_link ) {
            formData.append(`link[${ i }]`, this.brochure_documents[i].video_link);
            formData.append(`name[${ i }]`, this.brochure_documents[i].name);
          }
          formData.append(`file_id[${ i }]`, this.brochure_documents[i].id ? this.brochure_documents[i].id : "");
        }
        formData.append("title", this.formFields.title ? this.formFields.title : "");
        formData.append("id", this.formFields.id);

        const response = await request({
          url: this.formFields.id ? '/brochures/update' : '/brochures/create',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });

        const { data } = response.data;

        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          console.log(this.formErrors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/brochures/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/brochures/detail/${ id }`,
        });
        const { data } = response.data;
        this.formFields = {
          ...data,
        };

        if ( data.media.length ) {
          _.map(data.media, (item) => {
            this.brochure_documents.push({
              id: item.id,
              name: item.name,
              previewFile: item.download_url,
              original_file_name: item.original_file_name,
              video_link: item.link,
              token: item.token,
              type: item.type
            });
          });
        }

      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    onChangeFile(payload) {
      this.fFile = null;
      this.fPreview = null;
      this.fFile = this.$refs.file.files[0];
    },
    bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if ( bytes == 0 ) {
        return '0 Byte';
      }
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "brochureList" });
    },
  },
};
